.best-section{
  min-height: 100px;
flex-shrink: 0;
/* border-radius: 20px 20px 0px 0px; */
background: #F8F3F5;
margin-top: 54px;
display: flex;
flex-direction: column;
align-content: center;
align-items: center;
padding-bottom: 3rem;
}

.njt-logo-best-section-img{
  width: 168px;
min-height: 64px;
flex-shrink: 0;
margin-top: 61px;
}

.best-section-title{
  color: #101010;
text-align: center;
font-family: Montserrat;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 100%; /* 50px */
max-width: 852px;
margin-top: 21px;
padding: 0 5%;
}


.best-section-desc{
  color: var(--text, #1D1D21);
text-align: center;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 120% */
max-width: 773px;
margin-top: 21px;
}

.best-benefits-tiles {
  justify-content: center;
  padding-bottom: 82px;
  display: flex;
  flex-wrap: wrap;
  gap: 67px;
  margin-top: 70px;
  max-width: 1196px;
}

.best-benefit-item {
  

  display: flex;
  justify-content: center;
  align-items: center;
}

.column-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .column-item {
  justify-content: center;

  display: flex;
} */


.best-benefit-item-image{
  width: 125px;
height: 125px;
flex-shrink: 0;
border-radius: 20px;
background: rgba(129, 56, 91, 0.10);
justify-content: center;
}

/* .best-benefit-item-image svg{
  width: 80px;
height: 80px;
flex-shrink: 0;
padding: 23px;
} */

.best-benefit-item-title{
  color: #81385B;
text-align: center;
font-family: Montserrat;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 85.714% */
max-width:286px;
margin-top: 25px;
}

.best-benefit-item-description{
  color: #1F3738;
text-align: center;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 120% */
max-width: 322px;
margin-top: 7px;
}

.carousel-arrow {
  padding: 0 2rem ;
  display: flex;
}

.carousel-dots-best {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.carousel-dots-best .dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ddd;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}



.carousel-dots-best .dot.active {
  background-color: #81385B;
}

@media (max-width: 800px) {

  .best-section{
    border-radius: 0px;
    margin-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .best-section-title{
    font-size: 44px;
    max-width: 500px;
    margin-top: 31px;
    
  }

  .best-section-desc{
    font-size: 15px;
     max-width: 550px;
    margin-top: 20px;
  }

  .best-benefits-tiles{
    margin-top: 50px;
    flex-direction: column;
    gap: 60px;
    padding-bottom: 94px;
  }

  .best-benefit-item-title{
    font-size: 20px;
    line-height: 24px;
    margin-top: 25px;
    max-width: 310px;
  }

  .best-benefit-item-description{
    max-width: 350px;
    font-size: 15px;
  }

  .best-benefits-tiles-wrapper{
    min-height: 325px;
  }

  .best-benefits-carousel {
    width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 49px;
  overflow: hidden;
    margin-top: 59px;
    padding-bottom: 30px;
    flex-direction: row;
    gap:2rem;
  }
  
}

@media (max-width: 550px) {

  

  .best-section-title{
    font-size:44px;
    line-height: normal;
    max-width: 70vw;
    margin-top: 31px;
  }

  .best-section-desc{
    font-size: 15px;
    line-height: normal;
    max-width: 80vw;
    margin-top: 20px;
  }

  .best-benefit-item-image{
    height: 100px;
    width: 100px;
    img{
      height: 100px;
      width: 100px;
    }
  }

  .best-benefit-item-title{
    font-size: 18px;
    line-height: normal;
    max-width: 300px;
  }

  .best-benefit-item-description{
    font-size: 15px;
    line-height: normal;
    max-width: 300px;
  }
}
