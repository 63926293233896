/* LandingPage.css */

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&display=swap');
@font-face {
  font-family: "GoldenPlains";
  src: url("./../fonts/Golden\ Plains.ttf") format("truetype");
}
.main-content {
    font-family: Arial, sans-serif;
  }
  
  .content {
    padding: 2rem;
    text-align: center;
    /* Add more styles for your content */
  }

  .downloadIcons {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 1rem;
    margin: 3rem auto;
  }
  
  .AvailableOnAppStore {
    width: 173.396px;
  }
  
  .GetItOnPlayStore {
    width: 173.396px;
  }
  