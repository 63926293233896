.footer-section{
  min-height: 400px;
  /* margin-top: 112px; */
  padding-bottom: 59px;
  background: #101010;
}

.footer-content{
  margin:0 auto;
  max-width: 1196px;
}

.footer-divider{
  max-width: 1197px;
height: 1px;
background: #254963;
}

.footer{
  padding-left:81px;
  padding-right:81px;
  padding-top: 59px;
  
  

}

.footer-desc{
  color: #505050;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 18px */
text-align: start;
margin-top: 28px;
}

.footer-ext-links{
  margin-top:45px ;
  display: flex;
  flex-direction: row;
  gap:61px
}

.footer-terms{
  color: #505050;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 18px */
text-decoration: none;
border: none;
background-color: inherit;
text-align: left;
cursor: pointer;
}

.footer-logo-awards{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.footer-logo-ext{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-logo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:15px
}

.abta-logo-footer{
  width: 113px;
height: 64px;
flex-shrink: 0;
}

.travel-aware-logo-footer{
  width: 57px;
height: 57px;
flex-shrink: 0;
}

.njt-footer-logo{
  width: 130px;
height: 53px;
flex-shrink: 0;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content {
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  width: 80% !important;
  max-width: 800px !important;
  height: 90% !important;
  padding: 20px !important;
}

.rpv-open__input-wrapper{
  display: none;
}

[aria-describedby="rpv-core__tooltip-body-theme-switch"] {
  display: none;
}


[aria-describedby="rpv-core__tooltip-body-full-screen-enter"] {
  display: none;
}

[data-testid="theme__switch-menu"] {
  display: none;
}


.pdf-viewer {
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.pdf-viewer::-webkit-scrollbar { 
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just to be sure */
}



@media (max-width: 800px){
  .footer{
    padding: 10vw;;
  }
  .footer-section{
    margin-top: 0px;
    padding: 0 0vw
  }
  .footer-ext-links{
    flex-direction: column;
    align-items: start;
    text-align: start;
    gap: 33px;

  }

  /* .footer-logo{
    flex-direction: column;
    align-items: start;
  } */

  .footer-divider{
    
    margin:81px;
    margin-top: 54px;
    margin-bottom: 0px;
  }

 
  
}