.offers-section {
  min-height: 1532px;
  flex-shrink: 0;
  border-radius: 20px;
  max-width: 1198px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  align-content: center;
  margin-top: 54px;
}

.offers-top-image {
  width: 206px;
  height: 51.277px;
  flex-shrink: 0;
  margin-top: 42px;
}

.offer-section-title {
  color: #101010;
  text-align: center;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 50px */
  /* max-width: 792px; */
  margin-top: 28px;
}

.offers-section-desc {
  color: var(--text, #1D1D21);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  max-width: 746px;
  margin-top: 29px;
}

.offers-section-desc-higlight {
  color: #81385B;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.offers-cards-wrapper {
  /* width: 100%; */
}

.offers-cards {
  margin-top: 49px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 49px;
}

.offers-cards-carousel {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 49px;
  overflow: hidden;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 2;
  padding: 10px;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.offer-card {
  width: 345px;
  height: 520px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.5s ease-in-out;
}

.offer-card-image {
  border-radius: 20px 20px 0px 0px;
  width: 345px;
  height: 260px;
  flex-shrink: 0;
}

.offer-card-type {
  color: #81385B;
  text-align: center;
  font-family: "GoldenPlains";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 75% */
  margin-top: 30px;
  max-width: 287px;
}

.offer-card-title {
  color: #1F3738;
  text-align: center;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 107.143% */
  max-width: 287px;
  margin-top: 13px;
}

.offer-card-starting-from {
  color: #1F3738;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  margin-top: 10px;
  max-width: 287px;
}

.offer-card-starting-from-price {
  color: var(--NJT-Primary, #81385B);
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  max-width: 287px;
  padding-bottom: 30px;
}

.offers-join-button:hover {
  background: #E43214;
}

.offers-join-button {
  border-radius: 100px;
  background: var(--F4978E, #F4978E);
  width: 262px;
  height: 71px;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  max-width: 261px;
  border: none;
  align-content: start;
  margin-top: 60px;
  margin-bottom: 60px;
  transition: background-color 0.3s ease;
cursor: pointer;
}

.app-info-images {
  margin: 32px;
  margin-top: 71px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  width: 100%;
}
.brand-img-large{
  height: 100%;
  width: 100%;
}

.brand-logo {
  max-width: 189px;
  max-height: 73px;
}

.deal-from-brand {
  color: #81385B;
  text-align: center;
  font-family: "GoldenPlains";
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 60% */
  margin-top: 72px;
  max-width: 90%;
}

@media (max-width: 1080px) {
  .deal-from-brand{
    margin-top: 40px;
    font-size: 50px;
  }
}

@media (max-width: 870px) {
  /* .deal-from-brand{
      margin-top: 10px;
      font-size: 33px; 
    
  } */
}

.carousel-arrow {
  padding: 0 2rem ;
  display: flex;
  
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.carousel-dots .dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ddd;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}



.carousel-dots .dot.active {
  background-color: #81385B;
}

@media (max-width: 800px) {
  .offers-cards-carousel {
    margin-top: 59px;
    padding-bottom: 30px;
    flex-direction: row;
    gap:2rem;
  }

  .offer-card {
    
    transform: scale(1);
  }

  

  .deal-from-brand{
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    margin-top: 42px;
  }
  .offer-section-title{
    padding-left: 21px;
    padding-right: 21px;
    font-size: 44px;
    line-height: 100%; 
    margin-top: 35px;
  }

  .offers-section-desc{
    margin-top: 37px;
    font-size: 18px;
    line-height: 24px; 
    padding-left: 21px;
    padding-right: 21px;
  }

  .offers-section-desc-higlight{
    font-size: 15px;
    line-height: 24px; 
  }

  .offers-top-image{
    margin-top: 53px;
  }

}

@media (min-width: 801px) {
  .carousel-arrow {
    display: none;
  }
}

@media (max-width: 550px) {
  

  .carousel-arrow {
    background-color: rgba(0, 0, 0, 0.3);
  }

  

  .offers-top-image{
    margin-top: 53px;
  }

  .offer-section-title{
font-size: 44px;
line-height: 100%; 
max-width: 350px;
  }

  .offers-section-desc{
    font-size: 18px;
    line-height: 24px; 
    max-width: 350px;
  }

  
}

@media(max-width:450px){
  .offers-cards-wrapper{
    transform: scale(0.75);
    margin-top: -100px;
  }

  /* .offers-cards-carousel{
    gap:2px
  } */

  .deal-from-brand{
    /* max-width: 350px;*/
    padding: 0 5vw;
    margin-top: -50px; 
  }
}

@media(max-width:390px){
  .offers-cards-wrapper{
    transform: scale(0.70);
   
  }
/* 
  .offers-cards-carousel{
    gap:1px
  } */
}

