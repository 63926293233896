/* Header.css */


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 29px;
  padding-bottom: 0px;
  color: #fff;

  top: 0;
  width: 100%;

  z-index: 10; /* Ensure the header is above the hero image */
  background: none;
  transform-origin: left top;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
  padding-bottom: 37px;
}

.phone-icon{
  height: 20px;
  padding-right: 0.5rem;


}

.phoneNumber {
  color: #000;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-decoration: none;
}

.logo {
  display: flex;
  
}

.logoSeperator {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.logo-container {
  margin-top: 10px;
  text-align: left;
}
.poweredBy {
  color: #81385B;
font-family: Montserrat;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 12px */
letter-spacing: 3px;
text-transform: uppercase;
}

.logo-img {
  width: 148px;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PITLogo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  flex-shrink: 0;
  background: none;
  cursor: pointer;
}



.logo-container img {
  height: 50px;
  width: auto;
  color: #fff;
}

.powered-by-logo-container {
  fill: #fff;
  width: 130px;
height: 53px;
flex-shrink: 0;
}

.cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
}
.cta-phone-text{
  color: #000;
text-align: right;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.cta-button:hover {
  background: #E43214;
}

.cta-button {
  color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 239px;
height: 54px;
flex-shrink: 0;
border-radius: 100px;
/* background: #F4978E; */
background: var(--F4978E, #F4978E);
border: none;
margin-left: 40px;
transition: background-color 0.3s ease;
cursor: pointer;
}



@media (max-width: 1330px){
  .logo{
    padding-left: 21px;
  }
}

@media (max-width: 1080px){
  .cta-phone-text{
  font-size: 18px;
  }

  .phoneNumber {
  font-size: 18px;
  }
  .cta-button{
    margin-left: 20px;
  }
/* 
  .cta-button {
    
  font-size: 14px;
  width: 170px;
  height: 60px;
  } */
}



@media (max-width: 1030px){
  .cta-phone-text{
  font-size: 16px;
  text-wrap: balance;
  }

  .phoneNumber {
  font-size: 16px;
  }

  .cta-button {
    
  /* font-size: 11px;
  width: 120px;
  height: 40px; */
  margin-left: 10px;
  }
}

@media (min-width: 800px){
 

  .hamMenu{
    
    display: none;
  }

  .phoneNumberIconLink{
    
    display: none;
  }
  }
/* Responsive design */
@media (max-width: 800px) {
  .header{
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .hamMenu{
    padding-left: 1rem;
    padding-right: 19px;
  }

  .phoneNumberIconLink{
    display: initial;
    max-width: 34px;
    max-height: 34px;
    padding-right: 19px; 
    position: absolute;
    margin-left: -40px;
    
  }

  .phoneNumberIcon{
    padding-top: 1.5vw;
    max-height: 40px;
    max-width: 40px;
  }

  .cta {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    justify-content: end;
    
    padding-left: 1rem;
    padding-right: 19px;
  }
  
  
  
   .cta-phone-text {
    display: none;
  }

  .phoneNumber{
    display: none;
  }

  


  

  .logo {
    flex-basis: 100%; /* Adjust if you're changing the flex-direction */
   padding-left: 21px;
    order: -1;
    justify-content: left;
    align-items: center;
    transform: scale(.9);
    transform-origin: left top;
    transition: transform 0.3s ease-in-out;
  }
}

/* @media (max-width: 600px) {
  .cta-button {
    
    width: 100px;
height: 34px;
font-size: 9px;
margin: 0;

  }
} */



@media (max-width: 600px) {

  .phoneNumberIcon{
    max-width: 35px;
    max-height: 35px;
  }

  .logo-img{
    display: contents;
  }

  .header{
    align-items: start;
    height: 42px;
    
  }
  /* .cta-button {
    
    width: 204px;
height: 51px;
font-size: 15px;
margin: 0;

  } */

  .cta{
    transform: scale(0.8);
    transform-origin: top right  ;
  }

 

  .logo{
    width: 154px;
    height: 74px;
    transform: scale(.56);
    transform-origin: left top;
    transition: transform 0.3s ease-in-out;
  }

  
  /* .header{
    flex-direction: column;
   
    align-items: start;
    justify-items: baseline;
  }
  .cta{
    padding-top: 1rem;
    width: 96%;
    padding-right: 1rem;
    align-items: center;
    justify-content: space-between;
    flex-basis: 100%;
  } */
    
}

@media (max-width: 450px) {

  /* .phoneNumberIcon{
    max-width: 34px;
    max-height: 34px;
  }
  
  .cta-button {
    
    width: 160px;
height: 38px;
font-size: 13px;
margin: 0;

  } */

  .phoneNumberIcon{
    padding-top: 2vw;
  }

  .cta{
    transform: scale(0.7);
    transform-origin:  top right ;
  }

}

@media (max-width: 400px) {
  .cta{
    transform: scale(0.6);
    transform-origin:   top center;
  }
}

@media (max-width: 390px) {
  .cta{
    margin-left: 2.5rem;
    transform: scale(0.6);
    transform-origin:   top left;
  }
}

@media (max-width: 350px) {
  .cta{
    transform: scale(1);
    transform-origin:   center right;
    margin-left: 2rem;
  }

  .phoneNumberIcon{
    transform: scale(0.7);
    transform-origin:   center right;
    padding-top: 0;
    max-width: 34px;
    max-height: 34px;
  }
  
  .cta-button {
    
    width: 150px;
height: 31px;
font-size: 12px;
margin: 0;

  }

}

@media (max-width: 340px) {

 .cta{
  transform: scale(1);
 }
  .header{
    flex-direction: column;
   padding-bottom: 2.5rem;
    align-items: start;
    justify-items: baseline;
  }

 .phoneNumberIconLink{
  margin-left: 0;

 }
  .cta{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-basis: 100%;
    width: 100%;
  
    
  } 
  .cta-button{
    margin-left: 50px;
  }

 

 




  
}
