.tc-section{
  min-height: 627px;
flex-shrink: 0;
/* border-radius: 0px 0px 20px 20px; */
background: #81385B;
}

.tc-and-enquiry{
  margin: 0 auto;
  max-width: 1196px;
  padding-top: 73px;
  display: flex;
  flex-direction: row;
  gap:128px;
  align-items: center;
  justify-content: space-around;
}

.tc-info{
  display: flex;
  flex-direction: column;
  margin-left: 79px;
  align-items: center;
}

.tc-profile-pic{
  width: 250px;
height: 250px;
flex-shrink: 0;
border-radius: 250px;
}

.tc-profile-pic img{ 
  width: 250px;
height: 250px;
flex-shrink: 0;
border-radius: 250px;
justify-content: center;
object-fit: cover;
object-position: top;
}

.tc-name{
  margin-top: 21px;
  max-width: 224px;
  color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: 100%; /* 25px */
}

.tc-contact-number{
  color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 100%; /* 18px */
max-width: 224px;
margin-top: 6px;
}

.tc-enquiry-section{
  display: flex;
  flex-direction: column;
  align-items:start ;
  text-align: start;
  margin-right: 101px;
}

.enquiry-top-red-line{
  width: 218px;
height: 8px;
flex-shrink: 0;
background: #F4978E;
}

.enquiry-title{
  color: #FFF;
font-family: Montserrat;
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: 100%; /* 50px */
max-width: 638px;
margin-top: 19px;

}

.enquiry-description{
  color: #FFF;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 125%; /* 22.5px */
max-width: 633px;
margin-top: 30px;
}

.enquiry-button:hover {
  background: #E43214;
}

.enquiry-button{
  color: var(--White, #FFF);
text-align: center;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
flex-shrink: 0;
border: #81385B;
width: 239px;
height: 60px;
flex-shrink: 0;
border-radius: 100px;
transition: background-color 0.3s ease;
cursor: pointer;
background: var(--F4978E, #F4978E);
margin-top: 31px;
}

.star-rating-list{
  margin: 0 auto;
  max-width: 1196px;
  margin-top: 65px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 100px;
  padding-bottom: 73px;
  justify-content: space-around;
  align-items: center;
}

.star-rating{
  display: flex;
  flex-direction: column;
  max-width: 330px;
  align-items: center;

}

.star-icon{
  width: 31px;
height: 31px;
flex-shrink: 0;
}

.star-rating-title{
  color: var(--DFC7C7, #DFC7C7);
text-align: center;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 100% */
letter-spacing: 2px;
margin-top: 5px;
}

.star-rating-desc{
  color: var(--F4978E, #F4978E);
text-align: center;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 133.333% */
letter-spacing: 1.5px;
}



@media (min-width: 800px){
 

  .mobile-view{
    
    display: none;
  }
  }

@media (max-width: 800px) {
  
  .tc-section{
    border-radius: 0px;
  }
.star-rating-list{
  flex-direction: column;
  align-items: center;
  margin-top: 37px;
  gap: 48px;
}

.tc-and-enquiry{
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 34px;
}

.tc-info{
  margin-left: 0px;
}

.tc-enquiry-section{
  margin-right: 0px;
  align-items: center;
  text-align:center ;
}
.enquiry-title{
  padding-right: 22px;
padding-left: 22px;
}
.enquiry-description{
  padding-right: 22px;
padding-left: 22px;
}

.desktop-view{
  display: none;
}
}

@media (max-width: 550px) {

  /* .tc-enquiry-section {
    transform: scale(0.8);
    transition: transform 0.3s ease-in-out;
  } */
  
  

  .star-rating-list{
    transform: scale(0.8);
    transition: transform 0.3s ease-in-out;
  }

  .enquiry-title{
    font-size: 35px;
  }


}