.benefits-section {
  margin-top: 119px;
  font-family: 'Montserrat';
  /* min-height: 957px; */
  border-radius: 20px 20px 0px 0px;
  background: #FDF9F4;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 74px;
}

.benefits-logo {
  max-width: 127.059px; 
  height: 127.059px;
  position: absolute;
  margin-top: -55px;
  display: flex;
}

.benefits-title {
  color: #1F3738;

  text-align: center;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  max-width: 813px;
  margin-top: 90px;
  
  
}

.benefits-title span
    {
      flex-shrink: 0;
    
      position: relative;
      background-image: url("images/benefits/underline.svg");
      /* Path to your SVG */
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 100%;
      /* Adjust the height of the underline as needed */
      padding-bottom: 3px;
    }

/* .benefits-underline-title {
  flex-shrink: 0;

  position: relative;
  background-image: url("images/benefits/underline.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
 
  padding-bottom: 3px;
} */

.benefits-title-description {
  color: var(--text, #1D1D21);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 120% */
  max-width: 565px;
  margin-top: 43px;

}

.benefits-cards {
  display: flex;
  flex-direction: row;
  margin-top: 58px;

}

.benefits-card {
  border-radius: 20px;
  background: #FFF;

  width: 345px;
  height: 520px;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;

}

.benefits-plus {
  background-image: url("images/benefits/plus-circle.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  width: 75px;
  height: 75px;
  flex-shrink: 0;
  position: relative;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 224px;



}

.card-image {
  width: 345px;
  height: 260px;
  flex-shrink: 0;
  border-radius: 20px 20px 0px 0px;
}


.card-type {
  color: #E0A64F;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 24px */
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-top: 47px;
}

.card-title {
  color: #1F3738;
  text-align: center;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 107.143% */
  max-width: 345px;
  margin-top: 17px;
  padding-left: 25px;
  padding-right: 25px;
}

.card-description {
  color: #1F3738;

  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 285px;
  margin-top: 17px;
  padding-left: 15px;
  padding-right: 15px;
}



@media (max-width: 1180px) {
  .benefits-cards {
    margin-top: 40px;
    transform: scale(0.9);
    transition: transform 0.3s ease-in-out;
  }

}

@media (max-width: 1080px) {
  .benefits-cards {
    margin-top: 30px;
    transform: scale(0.85);
    transition: transform 0.3s ease-in-out;
  }

  /* .benefits-section {
    min-height: 880px;
  } */

}

@media (max-width: 1024px) {
  .benefits-cards {
    margin-top: 20px;
    transform: scale(0.8);
    transition: transform 0.3s ease-in-out;
  }

}

@media (max-width: 930px) {

  .benefits-cards {
    margin-top: 10px;
    transform: scale(0.75);
    transition: transform 0.3s ease-in-out;
  }

}

@media (max-width: 870px) {
  .benefits-cards {

    margin: -5px 0px;
    transform: scale(0.70);
    transition: transform 0.3s ease-in-out;
  }

}

@media(max-width: 800px) {
  .benefits-section {
    width: 100%;
    min-height: 1000px;
    border-radius: 0px;
    margin-top: 50px;
  }

  .benefits-logo {
     max-width: 84px; 
    height: 84px;
    flex-shrink: 0;
    margin-top: -48px;
  }

  .benefits-cards {
    display: contents;
    margin-top: 40px;
    min-height: 1350px;
    transform: scale(1);
  }




  .benefits-title {
    color: #101010;
    text-align: center;
    font-family: Montserrat;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 25px */
    max-width: 70vw;
    margin-top: 64px;
    /* padding-left: 9px; */
    padding-top: 9px;
  }

  .benefits-title-description {
    max-width: 80vw;
    color: var(--text, #1D1D21);
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 160% */
    margin-top: 20px;
    padding-bottom: 40px;
    /* padding-left: 19px; */
    padding-top: 19px;
    
  }

  .benefits-plus {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: -21px;
    margin-bottom: -21px;
  }
}

@media (max-width: 450px) {
  .benefits-cards{
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(0.95);
    margin: -50px 0px;
   
  }

  .benefits-section{
    min-height: 500px;
  }

}

@media (max-width: 400px) {
  .benefits-cards{
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(0.9);
    margin: -100px 0px;
   
  }

  .benefits-section{
    min-height: 500px;
  }

}