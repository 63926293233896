.testimonial{
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 391px;
flex-shrink: 0;
background: #FFF;
margin-top: 71px;
}

.testimonial-quotes{
  color: #81385B;
text-align: center;
font-family: Montserrat;
font-size: 100px;
font-style: normal;
font-weight: 900;
line-height: 100px; /* 100% */
width: 60px;
height: 61px;
flex-shrink: 0;
padding-top: 63px;
}

.testimonial-description{
  color: var(--text, #1D1D21);
text-align: center;
font-family: Montserrat;
font-size: 26px;
font-style: italic;
font-weight: 300;
line-height: 36px; /* 138.462% */
max-width: 916px;
flex-shrink: 0;
padding-left: 15px;
padding-right: 15px;
margin-top: 19px;
}

.testimonial-name{
  color: #81385B;
text-align: center;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 125%; /* 22.5px */
max-width: 470px;
margin-top: 37px;
padding-bottom: 63px;
}

@media (max-width: 800px){

  .testimonial{
    height: 263px;
  }

  .testimonial-quotes{
    color: #81385B;
  text-align: center;
  font-family: Montserrat;
  font-size: 100px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px; /* 100% */
  width: 60px;
  height: 61px;
  flex-shrink: 0;
  padding-top: 0px;
  }

  .testimonial-description{
    color: var(--text, #1D1D21);
text-align: center;
font-family: Montserrat;
font-size: 15px;
font-style: italic;
font-weight: 300;
line-height: 20px; /* 133.333% */
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 16px;
  max-width: 80vw;
  }
  
  .testimonial-name{
    color: #81385B;

text-align: center;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 125%; 
  margin-top: 20px;
  padding-bottom: 63px;
  }
}

@media (max-width: 450px){
  .testimonial{
    height: 300px;
  }
}

