.hero-container {
  position: relative;
  
  border-radius: 18px;
  width: 100%;
  min-height: 634px;
  
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  flex-direction: row;
  align-items: center;
  text-align: center;
  color: white;
  /* Adjust text color based on your image */
}

.hero-image-container{
  height: 100%;
  min-height: 634px;
  width: 100%;
  /* background-repeat: no-repeat; */
  align-items: end;
/*  
  background-position: 0 75%; */
  border-radius: 20px;
  display: flex;
  background-size: auto 100%; 
  background-position: right center; /* Start from left top corner */
  background-repeat: inherit;
}

.hero-content {
  display: flex;
  position: relative;

  flex-direction: column;
  align-items: start;
  border-radius: 20px 0px 0px 20px;
  background: #320729;

  padding: 0rem;
  text-align: left;
  min-height: 634px;
  width: 753%;
  max-width: 753px;
  transform: scale(1);
  transform-origin: left top;
  transition: transform 0.3s ease-in-out;
  padding-bottom: 37px;
}



.PIT-name {
  margin-top: 97px;
  margin-bottom: 0px;
  padding-left: 61px;
  max-width: 400px;

  color: var(--F1F2EB, #F1F2EB);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 18px */
  letter-spacing: 3px;
  text-transform: uppercase;
}

.hero-title {
  margin-top: 14px;
  padding-left: 61px;
  margin-bottom: 0px;
  max-width: 632px;
  color: var(--White, #FFF);
  font-family: Montserrat;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.hero-description {
  margin-top: 33px;
  padding-left: 61px;
  margin-bottom: 0px;
  max-width: 497px;
  color: var(--White, #FFF);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 22.5px */

}

.hero-cta{
  margin-left: 61px;
  margin-top: 47px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hero-cta-button {
  width: 219px;
  height: 60px;
  border: none;
  
  
  flex-shrink: 0;
  border-radius: 100px;
  background: var(--F4978E, #F4978E);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--White, #FFF);
text-align: center;
font-family: Montserrat;
font-size: 18.323px;
font-style: normal;
font-weight: 700;
line-height: normal;
transition: background-color 0.3s ease;
cursor: pointer;
}

.hero-cta-button:hover {
  background: #E43214;
}

.hero-cta-button-learn-more:hover {
  background-color: #E43214; 
  border: 2px solid #E43214; 
  color: #FFF; 
}


.hero-cta-button-learn-more {
  border-radius: 100px;
border: 1px solid #F4978E;
transition: background-color 0.3s ease;
cursor: pointer;
width: 219px;
height: 60px;
flex-shrink: 0;
 margin-left: 10px;
/*margin-right: 10px; */

justify-content: center;
background-color: inherit;

color: #F4978E;
text-align: center;
font-family: Montserrat;
font-size: 18.323px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.hero-referer{
  margin-left: 61px;
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  
  width: 274px;
  
}
.abta-hero-logo{
  width: 127.658px;
  height: 34.816px;
  flex-shrink: 0;
  
}

.trustpilot-hero-logo{
  width: 105.737px;
height: 49px;
margin-left: 41px;
  flex-shrink: 0;
}





@media (max-width: 1200px){
  .hero-content{
    width: 100%;
   max-width: 55%;
   }
  .hero-cta{
    margin-top: 0px;
    flex-direction: column;
    
  }
  .PIT-name{
    font-size: 10px;
  }
  .hero-title{
    font-size: 44px;
  }
  .hero-description{
    font-size: 18px;
  }
  .hero-cta-button, .hero-cta-button-learn-more{
    /* font-size: 18px;
    height: 56px;
    width: 190px;  */
    margin-left: 0;
    margin-top:20px;
    
  } 
 
 
 }


 @media (max-width: 800px) {
  .hero-container{
    flex-direction: column-reverse;
    border-radius: 0px;
    min-height: 698px;
    width: 100%;
    align-items: end;
    background-image: none;
  }

  .hero-image-container{
    background-image: none;
    
  }

 
  
  .hero-image{
    
background-repeat: no-repeat;
background-size: cover; 
 background-position:center center; 
    
    border-radius: 0px;
    width: 100%;
    height: 185px;
    padding: 15px;
    
  }

  

  .hero-content{
    width: 100%;
    min-height: 513px;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 0px;
    display: flex;
    position: relative;
    max-width: 800px;
    text-align: center;
    /* padding: 15px; */
  }

  .PIT-name{
    margin: 0px;
    padding: 0px;
    
  }

  .hero-title{
    margin-top: 20px;
    padding: 0 10vw;
  
  }

  .hero-description{
    padding: 0 5vw;
    margin-top: 20px;
  }

  .hero-cta{
    margin-left: 0;
  }
  .hero-cta-button, .hero-cta-button-learn-more{
    margin-left: 0px;
    margin-top: 30px;
  }

  .hero-referer{
    margin-top: 21px;
    margin-left: 0px;
    padding: 0px;
  }


 }


@media (max-width: 550px) {

  .PIT-name{
   font-size: 10px;
    
  }

  .hero-title{
    font-size: 35px;
  
  }

  .hero-description{
    font-size: 18px;
  }
  .hero-cta-button{
   width: 204px;
   height: 51px;
   
   font-size: 15px;
  }

  .hero-cta-button-learn-more{
    width: 204px;
    height: 51px;
   }

   .hero-cta{
   
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
 

  

}

@media (max-width: 340px) {
  .hero-container{
    margin-top: 2rem;
  }
}

