.video-section {
  margin-top: 26px;
  width: 100%;
  min-height: 0px;
  flex-shrink: 0;
  border-radius: 20px;
 
   /* background: rgba(30, 107, 163, 0.06);  */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .svg {
    margin-top: 43px;
    width: 153px;
height: 42.303px;
  }
}

.plane-icon-lines {
  margin-top: 43px;
}

.video-title {
  color: #101010;

  text-align: center;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 50px */
  max-width: 80vw;
  margin-top: 32px;
}

.video-description {
  color: var(--text, #1D1D21);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 120% */
  margin-top: 31px;
  max-width: 80vw;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (height / width = 9 / 16 = 0.5625) */
  height: 0;
  overflow: hidden;
  margin-top: 42px; /* This is your original margin-top */
}

/* .video-ref-link {
  width:100%;
  height:638px;
  max-width: 1135px;
  max-height: 638px;
  
  flex-shrink: 0;
  margin-top: 42px;
  
  
} */
.video-ref-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  flex-shrink: 0;
}

.video-ref-link iframe{
  border: none;
}

.get-app-button:hover {
  background: #E43214;
}

.get-app-button {
  border-radius: 100px;
  background: var(--F4978E, #F4978E);
  color: #FFF;
  width: 239px;
height: 60px;

  flex-shrink: 0;

  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border: none;
  margin-top: 47px;
  margin-bottom:47px
}

.video-benefits-tiles {
  max-width: 95vw;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 66px;
  margin-top: 70px;
}

.what-i-can-button{
  width: 200px;
height: 50.209px;
flex-shrink: 0;
border-radius: 100px;
border: 1px solid #F4978E;
background: #81385B;
}

.video-benefit-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
background: #F0FCFF;
max-height: 339px;
max-width: 325px;
padding-bottom: 50px;

}

.column-container-video {
  margin-top: 44px;
  justify-content: start;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-left: 50px;
  text-align: start;
}

/* .column-item {
  justify-content: center;

  display: flex;
} */


.video-benefit-item-image{
  width: 63.116px;
height: 59.892px;
flex-shrink: 0;
border-radius: 20px;
background: rgba(129, 56, 91, 0.10);
justify-content: center;
}

.video-benefit-item-image img{
  width: 63.116px;
  height: 59.892px;
}

/* .video-benefit-item-image svg{
  width: 80px;
height: 80px;
flex-shrink: 0;
padding: 23px;
} */

.video-benefit-item-title{
  color: #101010;
font-family: Montserrat;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 31px; /* 110.714% */
max-width:286px;
}

.video-benefit-item-description{
  color: #1F3738;
text-align: start;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 138.889% */
max-width: 242.613px;
margin-top: 7px;
}



@media (max-width:800px) {
  .video-section {
    width: 100%;
    max-width: 800px;

    /* min-height: 887px; */
    flex-shrink: 0;
    border-radius: 0;
    margin: 0;
    padding-bottom: 0px;
  }

  

  .video-title{
    margin-top: 19px;
    font-size: 44px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .video-description{
    margin-top: 20px;
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;

  }
  .plane-icon-lines{
    width: 153px;
height: 42.303px;

  }
  .get-app-button{
    width: 219px;
    height: 60px;
font-size: 18px;
/* margin-bottom: 80px; */
margin:  33px 15px;

  }
  .video-ref-link{
    height: 60vw;
    width: 100%;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .video-benefits-tiles {
    
    gap: 33px;
    margin-top: 33px;
  }
}

@media (max-width:750px) {
  .video-section {
    
    padding-bottom: 0px;
  }
}


@media (max-width:550px) {
  
  .video-section {
    width: 100%;
    max-width: 800px;

    /* min-height: 807px; */
    flex-shrink: 0;
    border-radius: 0;
    margin: 0;
  }
  

  .video-title{
    margin-top: 19px;
    font-size: 35px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .video-description{
    margin-top: 20px;
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;

  }
  .plane-icon-lines{
    width: 153px;
height: 42.303px;

  }
  .get-app-button{
    
font-size: 15px;
/* margin-left: 15px;
margin-right: 15px; */

  }
  
}

@media (max-width:400px) {
  .video-ref-link{
    width: 100%;
  }
  
}

