.app-section {
  min-height: 691px;
  flex-shrink: 0;
  background: #FDF9F4;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  flex-direction: row;
}

.app-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: start;
}
.benefit-app-info {
  color: #E0A64F;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 18px */
  letter-spacing: 3px;
  text-transform: uppercase;
  max-width: 275.945px;
  padding-top: 28px;
  /* padding-left: 60px; */
}

.app-title {
  color: #1F3738;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  /* 104.167% */
  max-width: 583px;
  margin-top: 21px;
  padding-left: 60px;
  text-align: left;
}

.app-benefit-item {
  display: flex;
  flex-direction: row;
  padding-left: 60px;
  margin-top: 51px;
  align-items: center;
  justify-content: left;
}

.tick-img {
  width: 25px;
  height: 25px;
  flex-shrink: 0;
}

.app-benefit-tick {
  border-radius: 100px;
  background: #E0A64F;
  width: 41px;
  height: 41px;
  flex-shrink: 0;
  align-content: center;
}

.app-benefit-desc {
  max-width: 449px;
  color: #1F3738;
  font-family: Montserrat;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 104.348% */
  padding-left: 27px;
}

.benefits-get-app-button:hover {
  background: #E43214;
}

.benefits-get-app-button {
  border-radius: 100px;
  background: #F4978E;
  width: 262px;
  height: 71px;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 120% */
  max-width: 261px;
  border: none;
  align-content: start;
  margin-left: 60px;
  margin-top: 60px;
  transition: background-color 0.3s ease;
cursor: pointer;
}

.app-img{
  border-radius: 0px 0px 20px 0px;
  background: #FDF9F4;
cursor: pointer;
}

@media (max-width: 1280px){
  

  .app-title{
    font-size: 40px;
    line-height: 40px;
  }

  .app-benefit-tick{
    width: 38px;
  height: 38px;
  }

  .tick-img{
    width: 23px;
  height: 23px;
  }

  .app-benefit-desc{
    font-size: 20px;
    text-align: start;
  }

  .app-benefit-item{
    margin-top: 40px;
  }

  .benefits-get-app-button{
    font-size: 18px;
    width: 230px;
    height: 60px;
  }

  .app-img{
    /* width: 50vw; */
    position: relative;
    align-content: end;
  }
 
 
 }

 @media (max-width: 1080px){
  

  .app-title{
    font-size: 35px;
    line-height: 35px;
  }

  .app-benefit-tick{
    width: 28px;
  height: 28px;
  }

  .tick-img{
    width: 20px;
  height: 20px;
  }

  .app-benefit-desc{
    font-size: 18px;
    line-height: 18px;
    text-align: start;
  }

  .app-benefit-item{
    margin-top: 35px;
  }

  .benefits-get-app-button{
    font-size: 18px;
    width: 180px;
  height: 55px;

  }

  .app-img{
     /* width: 60vw;  */
    position: relative;
    align-content: end;
  }
 
 
 }

 @media (max-width: 960px){
  .app-section{
    min-height: 550px;
  }

  .app-title{
    font-size: 30px;
    line-height: 30px;
  }

  .app-benefit-tick{
    width: 25px;
  height: 25px;
  }

  .tick-img{
    width: 20px;
  height: 20px;
  }

  .app-benefit-desc{
    font-size: 15px;
    line-height: 15px;
    text-align: start;
  }

  .app-benefit-item{
    margin-top: 30px;
  }

  .benefits-get-app-button{
    font-size: 15px;
    width: 180px;
  height: 55px;

  }

  

  .app-img{
     height: 550px;

    position: relative;
  
    justify-content: end;
    align-content: end;
  }
 
 
 }

 @media (max-width: 800px){
.app-section{
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex-basis: 100%;
  min-height: 808px;
  border-radius: 0px;
}
.app-text{
  width: 100%;
  text-align: center;
  align-items: center;
  
}

.app-benefit-desc {
   font-size: 18px;

}

.app-title{
  padding-left: 0px;
  max-width: 80vw;
  color: #101010;
text-align: center;
font-family: Montserrat;
font-size: 35px;
font-style: normal;
font-weight: 700;
line-height: 100%; /* 25px */
padding-left: 22px;
  padding-right: 22px;
}

.app-benefit-item{
  padding-left: 0px;
  margin-top: 30px;
  padding-left: 31px;
  padding-right: 31px;
}

.benefit-app-info{
  margin-left: 0px;
}

.benefits-get-app-button{
  margin-top: 30px;
  margin-left: 0px;
  margin-bottom:10px ;
}

.app-img-div{
  padding-top:40px ;
  min-height: 395px;
  width: 100%;
}
.app-img{
  height: 99%;
  width: 100%;
  border-radius: 0px;
}

 }

 

 @media (max-width: 550px){
  .app-section{
    min-height: 450px;
    /* margin-top:-150px; */
  }

  .app-img-div{
    min-height: 10px;
  }

 

  
 }

 @media (max-width: 450px){
  .app-title{
    font-size: 30px;
  }
  .app-benefit-desc{
    font-size: 15px;
  }

 

  
 }